import React, { Component } from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Introduction from "../../components/introduction"
import HeroAlt from "../../components/hero-alt"
import Spacer from "../../components/spacer"
import TitleAndText from "../../components/title-and-text"
import ImageAndText from "../../components/image-and-text"
import BulletList from "../../components/bullet-list"
import Bullet from "../../components/bullet"
import CenteredSlider from "../../components/centered-slider"
import CenteredSlide from "../../components/centered-slide"
import WorkGrid from "../../components/work-grid"
import ImageGridItemService from "../../components/image-grid-item-service"
import ImageGridItemPackage from "../../components/image-grid-item-package"
import ContactForm from "../../components/contact-form"
import ImageGridItem from "../../components/image-grid-item"
import Testimonials from "../../components/testimonials"


class CraftCmsPage extends Component {
  render() {
    const data = this.props.data

    return (
      <Layout background={ true } >
        <SEO title="Craft CMS Development Bournemouth" />
        <HeroAlt 
          image="/craft-cms.jpg"
          title="Craft CMS"
          subtitle="Looking for a more user-friendly and intuitive content management system? Craft CMS could be just what you're looking for."
        />
        
        <Spacer space="4" />    

        <div className="row shrink-width align-center">

          <div className="column small-12 large-4">
            <h2 className="gm-bottom--1" style={{ lineHeight: 1 }}>Craft CMS Development</h2>  
          </div>
          <div className="column small-12 large-8">
            <div className="row align-center">
              <div className="column small-12 large-10">
                <p>Craft CMS is a Content Management System built to deliver an exceptional content management experience. Unlike WordPress, which started out as a blogging platform and has subsequently been modified and expanded upon to make it a fully-fledged content management system, Craft CMS was built from the ground up specifically to be a fully-fledged content management system.</p>
                <p>Craft CMS is used by some of the biggest brands in the world such as Netflix, Sonos, Salesforce, Oakley and even the Dalai Lama himself.</p>
                <p>We have worked with Craft CMS for a number of years and consider it one of the best content management systems available. If you’ve had enough of fighting with WordPress to display your content exactly as you wish then get in touch to find out how we can take your website to the next level.</p>
                <Link className="button gm-top--1" to="/contact">Get in touch</Link>
              </div>              
            </div>
          </div>
        </div>

       <Spacer space="2" />

       <div className="gm-top--2 gm-bottom--2 contact-form--pullout">
          <div className="clearfix"></div>
          <div className="row align-center gm-top--4 gm-bottom--4">
            <div className="column small-12 medium-10 large-8">
              <h3>Get started</h3>
              <p>We're driven by bringing great ideas to life and working with great people. Get in touch to find out more.</p>
              <Spacer space="2" />
              <ContactForm pageName="craft-cms" />
            </div>
          </div>
          <div className="clearfix"></div>
        </div>

        <Spacer space="2" />

        <Testimonials />

        <Spacer space="4" />

        <WorkGrid>
          <ImageGridItemService width={6} image={ '/web-development.jpg' } subtitle="Development" title="Website Development" link="/services/website-development-bournemouth" />
          <ImageGridItemService width={6} image={ '/web-design.jpg' } subtitle="Design" title="Website Design" link="/services/website-design-bournemouth" />
        </WorkGrid>

        <Spacer space="2" />   
        <CenteredSlider>
          {data.allWordpressPost.edges.map(({ node }) => (
              <CenteredSlide key={node.id} title={node.title} image={ node.featured_media.localFile.childImageSharp.original.src } link={node.path} />
          ))}
        </CenteredSlider> 
      </Layout>
    )
  }
}

export default CraftCmsPage

export const pageQuery = graphql`
  query {
    allWordpressPost(sort: {fields: [date], order: DESC}) {
      edges {
        node {
          id
          slug
          path
          title
          featured_media {
            localFile {
              childImageSharp {
                original {
                  src
                }
              }
            }
          }        
        }
      }
    }
  }
`